$base-color: #3C8699;

$second-color: #456a74;
$lighter-color: #57bfda;


.notransition {
  transition: none !important;
}
.spe-blured
{
  filter: blur(10px);
}
.spe-bg-video {
  position: fixed;
  transition: all 200ms ease-in-out;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  
    video{
      position: absolute;
      top: -9850px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      
      margin: auto;
      min-width: 100%;
      min-height: calc(100% + 300px);
      width: auto;
      height: auto;   
    }
  
}
.spe-menu{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 200;

  box-shadow: 0 -2px 20px #000;
}
.spe-main{
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
 
  justify-content: center;
  overflow: hidden;
  .sp-screen{
    width: 100%;
   
    border: 1px #ffffffaa solid;
    box-shadow: $lighter-color 0 0 4px inset, $lighter-color 0 0 4px,  ;
    transition: all 200ms ease-out;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 10px;
    -moz-transition: none;
  }
  .sp-screen-download{
    width: 80%;
    border: 1px #ffffffaa solid;
    box-shadow: $lighter-color 0 0 4px inset, $lighter-color 0 0 4px,  ;
    transition: all 200ms ease-out;
    -moz-transition: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
  }
  .sp-screen-welcome{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    border: 1px #ffffffaa solid;
    box-shadow: $lighter-color 0 0 4px inset, $lighter-color 0 0 4px,  ;
    transition: all 200ms ease-out;
    -moz-transition: none;
    border-radius: 10px;
    >div{
      background-color: #fff;
      border-radius: 4px;
      margin: 8px;
      padding: 4em;
      padding-top: 0;
      text-align: center;
    }
  }
}

  .spe-contact{
    background-color: #fff;
    width: 100%;
  
    border-bottom-left-radius: 50px;
    display: flex;
    height: auto;
    overflow: visibility ; 
    margin-top: -1px;
  }

  .spe-photo-welcome-container{
  
    position: relative;
    width: 100%;
    height: 100px;
    z-index:6;
    margin-bottom: -1px;
    display: flex;
    justify-content: center;
  }
  .spe-photo-container{
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    position: relative;
    width: 100%;
    height: 80px;
    background-color:  $base-color;
    z-index:6;
    background: linear-gradient(180deg,  $base-color 0%, rgb(255,255,255) 100%);
    margin-bottom: -1px;
    display: flex;
    justify-content: center;
  }
  .spe-photo{
    position: absolute;
    border-radius: 50%;
    width: 140px;
    height: 140px;
    
    overflow: hidden;
    border: 4px solid #edf9ff;
    box-shadow: 0px 0px 10px $base-color;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 400ms linear;
    margin-top: -60px;
  }
  .spe-photo div{ 
    background-image: url('../../public/images/alexis.jpg');
    background-position: center 65%;
    background-size: cover;
    width: 100%;
    height: 100%;
    transition: all 400ms ease-in-out;
    z-index: -1;
    filter: brightness(1);

  }

  .spe-photo:hover div{
    transform: scale(1.2) ;
    filter:brightness(1.2);
  }
  .spe-photo:before {
    content: '';
    display: block;
    box-shadow: inset 0 0 20px #333;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }

  .spe-scrollable{
    
    max-height: calc(70vh - 56px);
    overflow: auto;
    margin-right: -14px;
    padding-right: 4px;
    border-bottom-left-radius: 50px;
    
   
  }
.spe-scrollable-download{
    
    max-height: 70vh;
    overflow: auto;
    margin-right: -24px;
    padding-right: 14px;
  }

  .spe-scroll-content{
    background-color: #ffffffaa;

  }
  .spe-scrollbar::-webkit-scrollbar {
    width: 10px;
   
  }
  
  /* Track */
  .spe-scrollbar::-webkit-scrollbar-track {
    background: #3b3b3b77; 
    border-radius: 5px;
  }
   
  /* Handle */
  .spe-scrollbar::-webkit-scrollbar-thumb {
    background: #ffffff; 
    border-radius: 5px;
   
  }
  
  /* Handle on hover */
  .spe-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #7ed5ff; 
  }
  
  


    @keyframes flickr {
      0%{
        opacity:0.5
      }
      25% {
        opacity:0.4
      }
      30% {
        opacity:0.6
      }
      35% {
        opacity:1
      }
      85% {
        opacity:1
      }
      100% {
        opacity:0.5
      }
    }
.flickr{
  animation: flickr 2s linear infinite;
}
    


.spe-turbulence-filter{
  -webkit-filter: url("#turbulence");
  filter: url("#turbulence");
}


.spe-hidden{
  display: none;
}
.spe-none-opacity{
  opacity: 0;
}
.spe-fadeOut{

  
  animation: fade 300ms linear ;
  animation-delay: 100ms;
  animation-fill-mode: forwards;
}
.spe-fadeIn{
 
  animation: fade 300ms linear reverse;
  animation-fill-mode: forwards;
}
@keyframes fade {
  0%{
    opacity:1;
    
  }
 
  100% {
    opacity:0;
   
  }
}


.spe-text-title{
  background: -webkit-linear-gradient(#ffffff93, #ffffff);
  color:#ffffffaa;
  font-size: 30pt;
  margin: 0;
  margin-bottom: -10px;
  margin-left: -3px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.spe-text-box{
  background-color:#ffffffff;
  font-size:18px;
  padding: 0.6em;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 1em;;
}


.spe-timeline{
  display: flex;
  
  .spe-timeline-point{
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $base-color;
  }
  .spe-timeline-text{
      border-left: 3px solid  $base-color;
      padding-left: 15px;
      margin-left: -12px;
     padding-bottom: 20px;
      flex: 1;
  }
}
.spe-language{
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
  > div{
    span{
      display: flex;
      font-size: 12pt;
      min-height: 50px;
      justify-content: center;
      align-items: center;
    }
    flex: 1;
    text-align: center;
  }
}
.spe-gauge{
  width: 100%;
  padding: 10px;
  display: flex;
  
  div{
    
    flex: 1;
    border: 1px solid #fff;
    
    width:calc(100% / 6);
    height: 10px;
    background-color: #ccc;
  }
  div:first-child{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  div:last-child{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.spe-a1 div:nth-child(1),
.spe-a2 div:nth-child(1),
.spe-b1 div:nth-child(1),
.spe-b2 div:nth-child(1),
.spe-c1 div:nth-child(1),
.spe-c2 div:nth-child(1){
  background-color: $base-color;
  box-shadow: $lighter-color 0 0 5px;
}
.spe-a2 div:nth-child(2),
.spe-b1 div:nth-child(2),
.spe-b2 div:nth-child(2),
.spe-c1 div:nth-child(2),
.spe-c2 div:nth-child(2){
  background-color: $base-color;
  box-shadow: $lighter-color 0 0 5px;
}

.spe-b1 div:nth-child(3),
.spe-b2 div:nth-child(3),
.spe-c1 div:nth-child(3),
.spe-c2 div:nth-child(3){
  background-color: $base-color;
  box-shadow: $lighter-color 0 0 5px;
}
.spe-b2 div:nth-child(4),
.spe-c1 div:nth-child(4),
.spe-c2 div:nth-child(4){
  background-color: $base-color;
  box-shadow: $lighter-color 0 0 5px;
}
.spe-c1 div:nth-child(5),
.spe-c2 div:nth-child(5){
  background-color: $base-color;
  box-shadow: $lighter-color 0 0 5px;
}
.spe-c2 div:nth-child(6){
  background-color: $base-color;
  box-shadow: $lighter-color 0 0 5px;
}


@media (min-width:900px){
  .spe-contact{
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(80vh - 139px);
  }
  .spe-scrollable{
    max-height: 80vh;
    margin-right: -30px;
    padding-right: 20px;
    border-bottom-left-radius: 0px;
    scroll-behavior: smooth;
  }
  .spe-photo{
    width: 200px !important;
    height: 200px  !important;
    margin-top: -70px  !important;
    border: 8px solid #edf9ff  !important;
  }
  .spe-photo-container{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;

    position: relative;
    width: 100%;
    height: 140px;
    
  }
  .spe-text-box{
    padding: 1em;
  }
  .spe-scrollable-download{
  
    padding-right: 24px;
  }
  .sp-screen-welcome{
    width: 60% !important;
  }
  .spe-photo-welcome-container{
    height: 140px!important;
  }
}