@import "../variables.scss" ;

.circle-container .circle
{
     transition: all 350ms ;
width:200px;
overflow:hidden;
 height:200px;
 border-radius:50%;
 margin-left:auto;
 margin-right:auto;
 border:5px solid #1f3850;
 cursor: pointer;
 }
  .circle-container:hover .circle {
      border:5px solid #fff;
    -webkit-box-shadow: 10px 10px 110px 0px $spe-base-color;
    -moz-box-shadow: 10px 10px 110px 0px $spe-base-color;
    box-shadow: 10px 10px 110px 0px $spe-base-color;
  }
 .circle-container .circle img {
  width:190px;
  height:190px;
  
  transform: scale(1);
  transition: all 350ms ;
  vertical-align:middle;
  text-align:center;
}
.circle-container:hover .circle img {
    transform: scale(1.2);
  }

  .circle-container h2
{
     transition: all 350ms ;

 color: $spe-base-color;

 }
 .circle-container:hover h2{
   color:#fff;
  }
   .circle-container
   {
       text-decoration: none!important;
   }


.btn-small-add-gear
{
    
    border-radius: 50% !important;
    position: absolute !important;
    padding: 0 !important;
    right: -10px;
    
}
.btn-small-add-gear .glyphicon
{
    top:0;
    font-size: 14;
}
.btn-small-remove-gear
{
   
    border-radius: 50% !important;
    position: absolute !important;
    padding: 0 !important;
    left: -10px;
    
}
.btn-small-remove-gear .glyphicon
{
    top:0;
    font-size: 14;
}
.ratio
{
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    background: #ccc;
    text-align: center;
    border-radius: 0px 0px 10px 10px;
    /* border: 1px solid #ccc; */
    line-height: 20px;
    font-size: 11px;
}
    .clockwise .axle {
    animation-name: axlerotation;
    }
    .counterclockwise .axle {
    animation-name: axlerotationinverse;
    }


    .clockwise  .gear-left
   {
       animation-name: gearrotationinverse;
    
   }
  .counterclockwise  .gear-left
   {
       animation-name: gearrotation;
   }
  .clockwise  .gear-right
   {
       margin-left:-4px;
        animation-name: gearrotation;
   }
  
  .counterclockwise  .gear-right
   {
       margin-left:-4px;
        animation-name: gearrotationinverse ;
   }

 .clockwise  .gear-left-worm .gear-worm
   {
        background-clip: content-box;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
       animation-name: wormrotation;
   }
 .counterclockwise  .gear-left-worm .gear-worm
   {
        background-clip: content-box;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
        animation-name: wormrotationinverse ;
   }


.gear-set-container
{
     
      display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
   width: fit-content;
    float: left;
    margin-bottom: 15px;
    margin-top: 15px;
}
   .gear-set
   {
     transition: all 550ms ;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    width: auto;
    max-width: fit-content;
    border-radius:10px 10px 0px 0px ;
   
    position: relative;
   }
  .gearselector
  {
      width:40px!important;
      height:40px!important;
      background-size:contain!important;
      float:left!important;
    margin: 5px;
       background-repeat:no-repeat!important;
       background-position: center center!important;
  }
   .gear-8
   {
       width: 28px;
       height: 28px;
       background: url('../../public/images/gears/gear_8.png');
   }
   .gear-8:hover
   {
     cursor:pointer;
     background: url('../../public/images/gears/h_gear_8.png');
   }
   .gear-12
   {
       width: 40px;
       height: 40px;
       background: url('../../public/images/gears/gear_12.png');
   }
   .gear-12:hover
   {
     cursor:pointer;
     background: url('../../public/images/gears/h_gear_12.png');
   }
      .gear-16
   {
       width: 51px;
       height: 51px;
       background: url('../../public/images/gears/gear_16a.png');
   }
   .gear-16:hover
   {
     cursor:pointer;
     background: url('../../public/images/gears/h_gear_16a.png');
   }
      .gear-20
   {
       width: 62px;
       height: 62px;
       background: url('../../public/images/gears/gear_20.png');
   }
   .gear-20:hover
   {
     cursor:pointer;
     background: url('../../public/images/gears/h_gear_20.png');
   }
   .gear-24
   {
       background: url('../../public/images/gears/gear_24a.png');
       width: 75px;
       height: 75px;
   }
   .gear-24:hover
   {
     cursor:pointer;
     background: url('../../public/images/gears/h_gear_24a.png');
   }
      .gear-28
   {
       width: 88px;
       height: 88px;
       background: url('../../public/images/gears/gear_28.png');
   }
   .gear-28:hover
   {
     cursor:pointer;
     background: url('../../public/images/gears/h_gear_28.png');
   }
      .gear-36
   {
       width: 108px;
       height: 108px;
       background: url('../../public/images/gears/gear_36.png');
   }
   .gear-36:hover
   {
     cursor:pointer;
     background: url('../../public/images/gears/h_gear_36.png');
   }
      .gear-40
   {
       width: 120px;
       height: 120px;
       background: url('../../public/images/gears/gear_40.png');
   }
   .gear-40:hover
   {
     cursor:pointer;
     background: url('../../public/images/gears/h_gear_40.png');
   }
      .gear-56
   {
       width: 166px;
       height: 166px;
       background: url('../../public/images/gears/gear_56.png');
   }
   .gear-56:hover
   {
     cursor:pointer;
     background: url('../../public/images/gears/h_gear_56.png');
   }
     .gear-worm
   {
       width: 30px;
       height: 45px;
       background: url('../../public/images/gears/worm.png');
       
   }
     .gear-worm:hover
   {
       cursor:pointer;
       background: url('../../public/images/gears/h_worm.png');
   }
.motor-seletor
{
    width:20px!important;
      height:20px!important;
      background-size:contain!important;
      float:left!important;
      margin-right:10px!important;
       background-repeat:no-repeat!important;
       background-position: center center!important;
}
.motor-seletor-li
{
    width:100%!important;
    height:30px!important;
    background-size:contain!important;
    display: flex;
    align-items: center;
    margin-right:10px!important;
    background-repeat:no-repeat!important;
    background-position: left center!important;
    .motor-rpm{
      flex:1;
      line-height: 30px;
      text-align: end;
      font-size: 11px;
    }
}
.motor-seletor-text-li
{
   line-height: 30px;
   font-size: 18px;
   padding-left: 70px;
   flex:1
}
.motor-a
{
    width: 100%;
    cursor: pointer;
    float: left;
}
.motor-seletor-text
{
   
   float:left
     
}
.motor-2838
{
    background: url('../../public/images/gears/motor_2.png');
    width: 103px;
    height:62px;
}
.motor-71427
{
    background: url('../../public/images/gears/motor_3.png');
    width: 82px;
    height:94px;
}
.motor-47154
{
    background: url('../../public/images/gears/motor_4.png');
    width: 82px;
    height:128px;
}
.motor-2986
{
    background: url('../../public/images/gears/motor_1.png');
    width: 62px;
    height:40px;
}
.motor-5292
{
    background: url('../../public/images/gears/motor_15.png');
    width: 114px;
    height:100px;
}
.motor-nxt
{
    background: url('../../public/images/gears/motor_16.png');
    width: 103px;
    height:172px;
   
}
.gear-set-container .motor-nxt
{
      margin-right: -20px!important;
}
.motor-pf-medium
{
    background: url('../../public/images/gears/motor_8.png');
    width: 124px;
    height:62px;
}
.motor-pf-xl
{
    background: url('../../public/images/gears/motor_10.png');
    width: 124px;
    height:103px;
}
.motor-pf-train
{
    background: url('../../public/images/gears/motor_13.png');
    width: 111px;
    height:116px;
}
.motor-e-motor
{
    background: url('../../public/images/gears/motor_9.png');
    width: 124px;
    height:94px;
}
.motor-pf-large
{
    background: url('../../public/images/gears/motor_7.png');
    width: 144px;
    height:81px;
}
.motor-ev3-large
{
    background: url('../../public/images/gears/motor_17.png');
    width: 103px;
    height:228px;
   
}
.gear-set-container .motor-ev3-large
{
    margin-right: -20px!important;
}
.motor-ev3-medium
{
    background: url('../../public/images/gears/motor_12.png');
    width: 187px;
    height:104px;

}
.motor-pup-medium
{
    background: url('../../public/images/gears/motor_11.png');
    width: 123px;
    height:62px;
}
.motor-boost-ext
{
    background: url('../../public/images/gears/motor_6.png');
    width: 125px;
    height:78px;
}
.motor-boost-int
{
    background: url('../../public/images/gears/motor_14.png');
    width: 124px;
    height:142px;
}




.gear-right  .gear-8{transform:rotate(22.5deg);  }
.gear-right  .gear-12{transform:rotate(15deg);  }
.gear-right  .gear-16{transform:rotate(11.25deg);  }
.gear-right  .gear-20{transform:rotate(9deg);  }
.gear-right  .gear-24{transform:rotate(7.5deg);  }
.gear-right  .gear-28{transform:rotate(6.43deg);  }
.gear-right  .gear-36{transform:rotate(5deg);  }
.gear-right  .gear-40{transform:rotate(4.5deg);  }
.gear-right  .gear-56{transform:rotate(3.21deg);  }

 
.gear{
     
    background-clip: content-box;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
   @keyframes gearrotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
 @keyframes gearrotationinverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.axle-text{
    box-shadow: 0px 0px 3px #ccc;
     margin-left: auto;
    margin-right: auto;
    position: relative;
    display: block;
    background: $spe-base-color-lighter;
    color: #000;
    width: 80px;
    text-align: center;
    line-height: 15px;
    margin-top: -26px;
    font-size: 12px;
    border-radius: 5px;
    padding: 0.5em;
    border: 1px solid $spe-base-color;
}

.axle {
      box-shadow: 0px 3px 3px #ccc;
	top: 50%;
    float: left;
     width:100px;

	height: 12px;
	font-family: "Exo", sans-serif;
	color: #fff;

background: -moz-linear-gradient(top, 
	rgba(238,238,238,1) 0%,
	rgba(238,238,238,1) 16%, 
	rgba(224,224,224,1) 17%,
	rgba(224,224,224,1) 32%,
	rgba(238,238,238,1) 33%,
	rgba(238,238,238,1) 49%, 
	rgba(224,224,224,1) 51%,
	rgba(224,224,224,1) 65%,
	rgba(238,238,238,1) 66%,
	rgba(238,238,238,1) 87%, 
	rgba(224,224,224,1) 88%,
	rgba(224,224,224,1) 100%);
background: -webkit-linear-gradient(top, rgba(238,238,238,1) 0%,
	rgba(238,238,238,1) 16%, 
	rgba(224,224,224,1) 17%,
	rgba(224,224,224,1) 32%,
	rgba(238,238,238,1) 33%,
	rgba(238,238,238,1) 49%, 
	rgba(224,224,224,1) 51%,
	rgba(224,224,224,1) 65%,
	rgba(238,238,238,1) 66%,
	rgba(238,238,238,1) 87%, 
	rgba(224,224,224,1) 88%,
	rgba(224,224,224,1) 100%);
background: -o-linear-gradient(top, rgba(238,238,238,1) 0%,
	rgba(238,238,238,1) 16%, 
	rgba(224,224,224,1) 17%,
	rgba(224,224,224,1) 32%,
	rgba(238,238,238,1) 33%,
	rgba(238,238,238,1) 49%, 
	rgba(224,224,224,1) 51%,
	rgba(224,224,224,1) 65%,
	rgba(238,238,238,1) 66%,
	rgba(238,238,238,1) 87%, 
	rgba(224,224,224,1) 88%,
	rgba(224,224,224,1) 100%);
background: -ms-linear-gradient(top, rgba(238,238,238,1) 0%,
	rgba(238,238,238,1) 16%, 
	rgba(224,224,224,1) 17%,
	rgba(224,224,224,1) 32%,
	rgba(238,238,238,1) 33%,
	rgba(238,238,238,1) 49%, 
	rgba(224,224,224,1) 51%,
	rgba(224,224,224,1) 65%,
	rgba(238,238,238,1) 66%,
	rgba(238,238,238,1) 87%, 
	rgba(224,224,224,1) 88%,
	rgba(224,224,224,1) 100%);
background: linear-gradient(to bottom, 
rgba(238,238,238,1) 0%,
	rgba(238,238,238,1) 16%, 
	rgba(224,224,224,1) 17%,
	rgba(224,224,224,1) 32%,
	rgba(238,238,238,1) 33%,
	rgba(238,238,238,1) 49%, 
	rgba(224,224,224,1) 51%,
	rgba(224,224,224,1) 65%,
	rgba(238,238,238,1) 66%,
	rgba(238,238,238,1) 87%, 
	rgba(224,224,224,1) 88%,
	rgba(224,224,224,1) 100%
);

	background-size: 100% 200%;
	animation-iteration-count: infinite;
 
  animation-timing-function: linear;
}

@keyframes axlerotation {
	0% {
		background-position: 0% 200%;
	}

	100% {
		background-position: 0% 0% ;
	}
}
@keyframes axlerotationinverse {
	0% {
		background-position: 0% 0%;
	}

	100% {
		background-position: 0% 200% ;
	}
}
@keyframes wormrotation {
	0% {
		background-position: 0% 46px;
	}

	100% {
		background-position: 0% 0% ;
	}
}
@keyframes wormrotationinverse {
	0% {
		background-position: 0% 0%;
	}

	100% {
		background-position: 0% 45px ;
	}
}
.gear-last-add{
  width:60px;
  height:60px;
  border-radius:50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid  $spe-base-color;
}
