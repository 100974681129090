@import "../variables.scss" ;

.header-parts{
  padding: 15px;
  background-color: $spe-base-color-dark;
  border: 1px solid $spe-base-color-light;
  color: #fff;
  border-bottom: 0;
  font-size: 16px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-letter{
  padding:15px;
  background-color: $spe-base-color-lighter;
  border: 1px solid $spe-base-color-light;
  border-bottom: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  font-size: 16px;
}
.sub-container{
  border: 1px solid $spe-gray-light;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 10px;
  min-height: 200px;
}
.userTextArea {
display: flex;
justify-content: end;
align-items: flex-end;
width: 100%;
padding-bottom:  15px ;

  textarea{
    flex:1;
    border-radius: 4px;
    border: 1px solid $spe-gray-light;
    background-color:  #fff;
    color: $spe-gray-dark;
    resize: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    overflow: auto;
    font-size: 16pt;
    font-weight: bold;
    line-height: 1.6em;
    height: 90px;
    padding: 0.4em;
    width: 300px;
    outline: none;
  }
  .textarea-label{
    border-radius: 4px;
    border: 1px solid $spe-base-color-light;
    background-color: $spe-base-color-lighter;
    color: $spe-base-color-dark;
    height: 90px;
    font-size: 12pt;
    border-top-right-radius: 0;
    border-right: 0;
    border-bottom-right-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    span{
      
      writing-mode: vertical-lr;
      transform: rotate(180deg);
    }
  }
  .textarea-counter{
    position: absolute;
    min-width: 50px;
    text-align: center;
    padding: 0.2em 1em;
    font-size: 11pt;
    background:$spe-gray-light;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.part-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  border: 1px solid $spe-gray-light;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 10px;
  background-color: $spe-base-color-lighter;
  max-height: calc(100vh - 180px);
  min-height: 500px;
  overflow: auto;
}
.part-box{
    flex: 1;
    padding: 0.3em;
    border-radius: 6px;
    margin: 0.5em;
    background: #ffffff;
    position: relative;
    box-shadow: 0 0 3px  rgb(197, 197, 197);
    img{
      width: 60px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
    }
    div{
      position: absolute;
      font-size: 12px;
      margin-top: -17px;
      margin-left: calc(-0.3em - 5px);
      background-color: #eee;
      border: 1px solid #777;
      box-shadow: 0 0 3px  #777;
      color: #333;
      font-weight: bold;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      
    }
}

.space-letter{
  width: 40px;
  display: inline-block;
  height: 100px;
}