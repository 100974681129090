@import "../variables";

.spe-header{
  backdrop-filter: blur(5px);
  background-color: rgb(60 60 60 / 95%);
  background: linear-gradient(180deg, rgb(52 52 52 / 85%) 0%, rgb(46 46 46 / 90%) 100%) !important;
  padding: 0 0;
  
}



@media (min-width: $layout-breakpoint-small) {
  .spe-header {
    padding: 10px 0;
  }
}