@import "../variables";

.spe-info-box.spe-info-box-base-7{
    border: 2px solid  #77a02f;
    .spe-left.spe-unskewed{
        background: #77a02f;
    }
  }
  .spe-info-box.spe-info-box-base-8{
    border: 2px solid  #000;
    .spe-left.spe-unskewed{
        background: #333;
    }
  }
  
  .spe-bg-h7{
    background: center center url('../../public/images/h7.jpg');
  }
  .spe-bg-h8{
     background: center center url('../../public/images/h8.jpg');
  }
  