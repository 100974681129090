@import "../variables";
.spe-photobanner-container {
  height:400px; 
  position:relative; 
  overflow:hidden;
  .spe-photobanner {
    position:absolute; 
    top:0px; 
    left:0px; 
    overflow:hidden; 
    white-space: nowrap;
    animation: bannermove 20s linear infinite;
    img {    
      margin: 0 0.5em ;
      border-radius: 6px;
    }
  }
}
@keyframes bannermove {
  0% {
      transform: translate(0, 0);
  }
  100% {
      transform: translate(-50%, 0);
  }
}




  
.spe-info-box.spe-info-box-base-5{
  border: 2px solid  $spe-base-color-dark;
  .spe-left.spe-unskewed{
      background: $spe-base-color-dark;
  }
}
.spe-info-box.spe-info-box-base-6{
  border: 2px solid  $spe-base-color-semi;
  .spe-left.spe-unskewed{
      background: $spe-base-color-semi;
  }
}

.spe-bg-h5{
  background: center center url('../../public/images/letter/generator.jpg');
}
.spe-bg-h6{
   background: center center url('../../public/images/letter/instrutions.jpg');
}
