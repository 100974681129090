.padTop{
    padding-top: 200px;
}

.line{
    &:nth-child(even){
        padding-left: 10px;
    }

    
    .col{
        width: 20px;
        height: 10px;
        float: left;
    }
}