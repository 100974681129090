@import "variables";
*{
  font-family: "Roboto","Helvetica","Arial",sans-serif;

}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.spe-container-top-gutter{
 padding: 1em;
 padding-top: 90px;
 width: 100%;
 }

.spe-container-fluid{
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
@media (min-width: $layout-breakpoint-small) {
  .spe-container-fluid {
    width: 90%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background:$spe-base-color;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $spe-base-color;
  cursor: pointer;
}




.beam {
  position: absolute;
  height: 24px;
  width: 48px;
  -moz-transform-origin: 12px 12px !important;
  -o-transform-origin: 12px 12px !important;
  -ms-transform-origin: 12px 12px !important;
  -webkit-transform-origin: 12px 12px !important;
  z-index: 100;
  opacity: 0.1;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}
.beam_good {
  opacity: 0.4;
}
.beamfocused {
  opacity: 0.9;
}
.beamunfocused {
  opacity: 0.02;
}
.beam_good .beam_start {
  background: url('../public/images/beams/angle_s_good.png');
  float: left;
  width: 24px;
  height: 24px;
}
.beam_good .beam_middle {
  background: url('../public/images/beams/angle_m_good.png');
  float: left;
  height: 24px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  text-overflow: hidden;
  font-size: 18px;
  text-shadow: -2px 0 4px #000000b0, 0 2px 4px #000000b0, 2px 0 4px #000000b0, 0 -2px 4px #000000b0;
}
.beam_good .beam_stop {
  background: url('../public/images/beams/angle_e_good.png');
  float: left;
  width: 24px;
  height: 24px;
}
.beam_bad .beam_start {
  background: url('../public/images/beams/angle_s_bad.png');
  float: left;
  width: 24px;
  height: 24px;
}
.beam_bad .beam_middle {
  background: url('../public/images/beams/angle_m_bad.png');
  float: left;
  height: 24px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  font-size: 18px;
  text-shadow: -2px 0 4px black, 0 2px 4px black, 2px 0 4px black,
    0 -2px 4px black;
}
.beam_bad .beam_stop {
  background: url('../public/images/beams/angle_e_bad.png');
  float: left;
  width: 24px;
  height: 24px;
}
.point_hipo_angle {
  cursor: pointer;
  z-index: 500;
  margin-left: 1px;
  width: 24px;
  height: 24px;
  position: absolute;
  font-weight: bold;
  border: 1px solid $spe-base-color-dark;
  border-radius: 50%;
  background: $spe-base-color-light;
  color: $spe-gray-dark;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.technic #beam_base {
  background: url('../public/images/beams/bg.png');
  width: 800px;
  height: 800px;
  float: left;
  position: relative;
}
.system #beam_base {
  background: url('../public/images/beams/s_bg.png');
  width: 800px;
  height: 800px;
  float: left;
  position: relative;
}
#beam_scale_y {
  float: left;
  width: 30px;
  height: 800px;
  margin-top: 30px;
  background: #f0f6f7;
}
.scale_hover {
  font-size: 14px !important;

  overflow: visible;
  position: relative;
  background: #3c8699;
  text-align: center;
}
#beam_scale_x {
  width: 800px;
  height: 30px;
  float: left;
  background: #f0f6f7;
}
#beam_scale_x > div {
  float: left;
  width: 12px;
  border-left: 1px solid #e0e0e0;
  height: 30px;
  line-height: 30px;
  font-size: 8px;
  text-align: center;
  color: transparent;
}
#beam_scale_y > div {
  float: left;
  width: 30px;
  border-top: 1px solid #e0e0e0;
  height: 12px;
  line-height: 10px;

  font-size: 12px;
  text-align: center;
  color: transparent;
}
.technic #beam,
.system #beam {
  position: relative;
  height: 24px;
  width: 48px;
  -moz-transform-origin: 12px 12px !important;
  -o-transform-origin: 12px 12px !important;
  -ms-transform-origin: 12px 12px !important;
  -webkit-transform-origin: 12px 12px !important;
  z-index: 900;
  filter: drop-shadow(rgba(45, 45, 45, 0.7) 2px 2px 2px);
}
.technic .beam_bad .beam_start {
  background: url('../public/images/beams/angle_s_bad.png');
  float: left;
  width: 24px;
  height: 24px;
}
.technic .beam_bad .beam_middle {
  background: url('../public/images/beams/angle_m_bad.png');
  float: left;
  height: 24px;
}
.technic .beam_bad .beam_stop {
  background: url('../public/images/beams/angle_e_bad.png');
  float: left;
  width: 24px;
  height: 24px;
}
.technic .beam_good,
.system .beam_good {
  opacity: 1 !important;
}
.technic .beam_good .beam_start {
  background: url('../public/images/beams/angle_s_good.png');
  float: left;
  width: 24px;
  height: 24px;
}
.technic .beam_good .beam_middle {
  background: url('../public/images/beams/angle_m_good.png');
  float: left;
  height: 24px;
}
.technic .beam_good .beam_stop {
  background: url('../public/images/beams/angle_e_good.png');
  float: left;
  width: 24px;
  height: 24px;
}
.technic .beam_middle .beam_start {
  background: url('../public/images/beams/angle_s_middle.png');
  float: left;
  width: 24px;
  height: 24px;
}
.technic .beam_middle .beam_middle {
  background: url('../public/images/beams/angle_m_middle.png');
  float: left;
  height: 24px;
}
.technic .beam_middle .beam_stop {
  background: url('../public/images/beams/angle_e_middle.png');
  float: left;
  width: 24px;
  height: 24px;
}
.system .beam_bad .beam_start {
  background: url('../public/images/beams/s_angle_s_bad.png');
  float: left;
  width: 24px;
  height: 24px;
}
.system .beam_bad .beam_middle {
  background: url('../public/images/beams/s_angle_m_bad.png');
  float: left;
  height: 24px;
}
.system .beam_bad .beam_stop {
  background: url('../public/images/beams/s_angle_e_bad.png');
  float: left;
  width: 24px;
  height: 24px;
}
.system .beam_good .beam_start {
  background: url('../public/images/beams/s_angle_s_good.png');
  float: left;
  width: 24px;
  height: 24px;
}
.system .beam_good .beam_middle {
  background: url('../public/images/beams/s_angle_m_good.png');
  float: left;
  height: 24px;
}
.system .beam_good .beam_stop {
  background: url('../public/images/beams/s_angle_e_good.png');
  float: left;
  width: 24px;
  height: 24px;
}
.system .beam_middle .beam_start {
  background: url('../public/images/beams/s_angle_s_middle.png');
  float: left;
  width: 24px;
  height: 24px;
}
.system .beam_middle .beam_middle {
  background: url('../public/images/beams/s_angle_m_middle.png');
  float: left;
  height: 24px;
}
.system .beam_middle .beam_stop {
  background: url('../public/images/beams/s_angle_e_middle.png');
  float: left;
  width: 24px;
  height: 24px;
}
.technic .point_hipo {
  z-index: 500;
  margin-left: 1px;
  width: 24px;
  height: 24px;
  position: absolute;
  background: url('../public/images/beams/bola.png');
}
.technic .point_hipo_good {
  background: url('../public/images/beams/bola_good.png');
}
.technic .point_hipo_normal {
  background: url('../public/images/beams/bola_0.png');
}

.system .point_hipo {
  z-index: 500;
  margin-left: 1px;
  width: 24px;
  height: 24px;
  position: absolute;
  background: url('../public/images/beams/s_bola.png');
}
.system .point_hipo_good {
  background: url('../public/images/beams/s_bola_good.png');
}
.system .point_hipo_normal {
  background: url('../public/images/beams/bola_1.png');
}
#beams_tooltip {
  z-index: 1000;
  position: absolute;
  background: #fff;
  border: 1px solid #e0e0e0;
  font-size: 14px;
 
  margin: 15px;
  display: none;
  width: 200px;

  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  border-radius: 4px;

  .tooltip_right:last-of-type{
  border-bottom: 0 !important;
  }

   .tooltip_left:last-of-type{
    border-bottom: 0 !important;
   }

  .tooltip_right{
    padding:  5px 10px;
   
    border-bottom: 1px solid  $spe-gray-light;
  }
  .tooltip_left{
    padding:  5px 10px;
    background-color: $spe-base-color-lighter;
    text-align: right;
    border-bottom: 1px solid $spe-base-color-light;
   
  }
}

.containerbase {
  overflow: auto;
  position: relative;
  height: calc(100vh - 200px);
  background: #F9F9F9;
  border-top: 1px solid #00A3D9;
  padding: 10px;
  width: 100%;
}


.spe-textarea-reader{
  resize: none;
  border-radius:5px;
  border:2px solid #333;
  background-color: #444;
  color:#fff;
  padding: 1em;
  
}
.spe-textarea-reader::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}

/* Track */
.spe-textarea-reader::-webkit-scrollbar-track {
  background: #555; 
  border-radius: 5px;
  cursor: pointer;
}
 
/* Handle */
.spe-textarea-reader::-webkit-scrollbar-thumb {
  background: #ffffff; 
  border-radius: 5px;
  cursor: pointer;
}

/* Handle on hover */
.spe-textarea-reader::-webkit-scrollbar-thumb:hover {
  background: #7ed5ff; 
}

.spe-info-box{
  --container-height: 350;
  --container-height-px: calc(var(--container-height) * 1px);
  overflow:hidden;
  border-radius:6px;
  align-items: center;
  display: flex;
  border-radius: 6px;
  transition: all 500ms ease-in-out;
  .spe-info-img-container{
      width: 100%;
      height: var(--container-height-px);
     overflow: hidden;
     z-index: 200;
     position:relative;
     .spe-info-img{
          position:relative;
          z-index: 100;
          width: 100%;
          height: var(--container-height-px);;
          background-size: cover;
          transition: all 500ms ease-in-out;
      }
      
  }
  .spe-info-img-container:before {
      content:'';
      display:block;
      z-index: 200;
      box-shadow:inset 0 0 20px #fff;
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
  }
   
  .spe-skewed-container{
      --angle: 10;

     width: 100%;
     position:relative;
     --skew-angle: calc(var(--angle) * 1deg);
     --skew-angle-neg: calc(var(--angle) * -1deg);
     --skew-diff:calc(var(--container-height) * tan( var(--skew-angle)));
     --skew-diff-px:calc(var(--skew-diff) * 1px);
     --skew-diff-half: calc((var(--skew-diff) / 2) * 1px);
     --skew-diff-half-neg: calc((var(--skew-diff) / 2) * -1px);
     height: var(--container-height-px);

     .spe-skewed{
         overflow:hidden;
         width:calc(50% + var(--skew-diff-half));
         position:absolute;
         transform:skew(var(--skew-angle-neg))
       }
       .spe-left.spe-skewed{
         left:var(--skew-diff-half-neg);
       }
       .spe-right.spe-skewed{
         right:var(--skew-diff-half-neg);
       }
       .spe-left.spe-unskewed{
         background:#ccc;
         transform:skew(var(--skew-angle));
         height:var(--container-height-px) ;
         left:var(--skew-diff-half);
         position:relative;
         display: flex;
         align-items: center;
         padding-left: 2em;
         padding-right:var(--skew-diff-px);
       }
       .spe-right.spe-unskewed{
         transform:skew(var(--skew-angle));
         height:var(--container-height-px);
         left:var(--skew-diff-half-neg);
         position:relative;
         background:#ffffff;
         
       }
   }
  

}


.spe-info-box:hover{
  box-shadow:0 0 20px #ccc;
  .spe-info-img-container{
   
     .spe-info-img{
      transform: scale(1.2);
      }
  }
}

.spe-base-color{
  color: $spe-base-color;
}
.spe-semibase-color{
  color: $spe-base-color-semi;
}




.fe-container-top-gutter{
 
  padding-top: 90px;
  width: 100%;
  }
 
 .fe-container-fluid{
   width: 95%;
   margin-left: auto;
   margin-right: auto;
   display: flex;
 }
 @media (min-width: $layout-breakpoint-small) {
   .fe-container-fluid {
     width: 80%;
   }
 }