@import "variables";


body{
  
  background: $spe-base-color-dark;

}


.spe-container{
 background-color: rgb(255, 255, 255);
 min-height: 500px;
 position: relative;
 display: flex;
 z-index: 0;
}
.spe-container-shadow{
  box-shadow: 0 0 30px 13px #00000079;
  display: block;
}



