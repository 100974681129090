
@import "../variables";

.home-section{
    min-height: calc(100vh - 90px);
    width: 100%;
    display: flex;
    align-items: center;
}


.home-section.section-two{
    background-color: #333;
    width: 100%;
}

.spe-welcome{
    background: center center url('../../public/images/bg_welcome.jpg');
    background-size: cover;
    min-height: 350px;
    align-items: center;
    padding: 2em 5em;
    border-radius: 6px;
}

  
.spe-info-box.spe-info-box-base-1{
    border: 2px solid  $spe-base-color-dark;
    .spe-left.spe-unskewed{
        background: $spe-base-color-dark;
    }
}
.spe-info-box.spe-info-box-base-2{
    border: 2px solid  $spe-base-color-semi;
    .spe-left.spe-unskewed{
        background: $spe-base-color-semi;
    }
}
.spe-info-box.spe-info-box-base-3{
    border: 2px solid  #F2DAC2;
    .spe-left.spe-unskewed{
        background:#F2DAC2;
    }
}
.spe-info-box.spe-info-box-base-4{
    --container-height: 450 ;
    border:2px solid #333333;
    .spe-left.spe-unskewed{
        background:#333333;
    }
       
    .spe-left.spe-skewed{
        width:calc(40% + var(--skew-diff-half) + 1px);
      }
      .spe-right.spe-skewed{
        width:calc(60% + var(--skew-diff-half) + 1px);
      }
  
}
.spe-bg-h1{
    background: center center url('../../public/images/h1.jpg');
 }
 .spe-bg-h2{
     background: center center url('../../public/images/h2.jpg');
  }
 .spe-bg-h3{
     background: center center url('../../public/images/h3.jpg');
  }
  .spe-bg-h4{
    background: right center url('../../public/images/h4.jpg');
 }




