@import "../variables.scss" ;

.letter-box{
  display: inline-block;
  padding: 0.7em;
  border: 1px solid #e7e7e7;
  border-radius: 6px;
  margin: 0.7em;
  box-shadow: 0 0 5px  #e7e7e7;
}

.letter-preview{
  width: 130px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;

  .brick-letter{
    filter: drop-shadow(2px 2px 6px #666);
    display: flex;
    transform: scale(0.3);
    justify-content: center;
    align-items: center;
    .br-bg1{
      width: 375px;
      height:363px;
      position: absolute;
      background-repeat: no-repeat;
      background-image: url('../../public/images/letter/bg1.png');
    }
    .br-bg2{
      transform: scale(1.01);
      width: 313px;
      height:311px;
      position: absolute;
      background-repeat: no-repeat;
      background-image: url('../../public/images/letter/bg2.png');
    }
    .br-letter{
      width: 250px;
      height:223px;
      position: absolute;
    
      background-repeat: no-repeat;
    }

    .color-g1-59{  background-position: 0 0;}
    .color-g1-2{  background-position: -375px 0;}
    .color-g1-6{  background-position: -750px 0;}
    .color-g1-11{  background-position: -1125px 0;}
    .color-g1-88{  background-position: -1500px 0;}
    .color-g1-3{  background-position: -1875px 0;}

    .color-g1-7{  background-position: 0 -363px;}
    .color-g1-1{  background-position: -375px -363px;}
    .color-g1-85{  background-position: -750px -363px;}
    .color-g1-86{  background-position: -1500px -363px;}
    .color-g1-5{  background-position: -1125px -363px;}





    
    .color-g2-85{ background-position: -0 -0;}
    .color-g2-59{ background-position: -313px -0;}
    .color-g2-11{ background-position: -626px -0;}
    .color-g2-6{ background-position: -939px -0;}
    .color-g2-86{ background-position: -1252px -0;}

    .color-g2-47{ background-position: -0 -312px;}
    .color-g2-39{ background-position: -313px -312px;}
    .color-g2-88{ background-position: -626px -312px;}
    .color-g2-63{ background-position: -939px -312px;}
    .color-g2-157{ background-position: -1252px -312px;}

    .color-g2-34{ background-position: -0 -624px;}
    .color-g2-2{ background-position: -313px -624px;}
    .color-g2-152{ background-position: -626px -624px;}
    .color-g2-48{ background-position: -939px -624px;}
    .color-g2-3{ background-position: -1252px -624px;}

    .color-g2-153{ background-position: -0 -936px;}
    .color-g2-7{ background-position: -313px -936px;}
    // .color-g2-1{ background-position: -626px -936px;}
    .color-g2-5{ background-position: -939px -936px;}
    .color-g2-1{ background-position: -1252px -936px;}


    .color-g3-110{ background-image: url('../../public/images/letter/l110.png');  }
    .color-g3-3{ background-image: url('../../public/images/letter/l3.png');  }
    .color-g3-1{ background-image: url('../../public/images/letter/l1.png');  }
    .color-g3-2{ background-image: url('../../public/images/letter/l2.png');  }
    .color-g3-88{ background-image: url('../../public/images/letter/l88.png');  }
    .color-g3-5{ background-image: url('../../public/images/letter/l5.png');  }
    .color-g3-86{ background-image: url('../../public/images/letter/l86.png');  }
    .color-g3-59{ background-image: url('../../public/images/letter/l59.png');  }
    .color-g3-85{ background-image: url('../../public/images/letter/l85.png');  }
    .color-g3-11{ background-image: url('../../public/images/letter/l11.png');  }
    .color-g3-63{ background-image: url('../../public/images/letter/l63.png');  }


    .color-g3-la{ background-position: 0 0;}
    .color-g3-lb{ background-position: -250px 0;}
    .color-g3-lc{ background-position: -500px 0;}
    .color-g3-ld{ background-position: -750px 0;}
    .color-g3-le{ background-position: -1000px 0;}
    .color-g3-lf{ background-position: -1250px 0;}

    .color-g3-lg{ background-position: 0 -223px;}
    .color-g3-lh{ background-position: -250px -223px;}
    .color-g3-li{ background-position: -500px -223px;}
    .color-g3-lj{ background-position: -750px -223px;}
    .color-g3-lk{ background-position: -1000px -220px;}
    .color-g3-ll{ background-position: -1250px -223px;}

    .color-g3-lm{ background-position: 0 -446px;}
    .color-g3-ln{ background-position: -250px -446px;}
    .color-g3-lo{ background-position: -500px -446px;}
    .color-g3-lp{ background-position: -750px -446px;}
    .color-g3-lq{ background-position: -1000px -446px;}
    .color-g3-lr{ background-position: -1250px -437px;}

    .color-g3-ls{ background-position: 0 -665px;}
    .color-g3-lt{ background-position: -250px -669px;}
    .color-g3-lu{ background-position: -500px -669px;}
    .color-g3-lv{ background-position: -750px -669px;}
    .color-g3-lw{ background-position: -1000px -669px;}
    .color-g3-lx{ background-position: -1250px -669px;}

    .color-g3-ly{ background-position: 0 -892px;}
    .color-g3-lz{ background-position: -250px -892px;}
    .color-g3-lplus{ background-position: -500px -892px;}

  }
}
.colors-selector{

  .color-selector.group-1{
    background-image: url('../../public/images/letter/group1.png');
  }
  .color-selector.group-2{
    background-image: url('../../public/images/letter/group2.png');
  }
  .color-selector.group-3{
    background-image: url('../../public/images/letter/group3.png');
  }
  .color-selector:first-child{
    margin-left:0;
  }
  .color-selector{
    display: inline-block;
    background-color: #f5f5f5;
    border-radius: 5px;
    width: 40px;
    border: 1px solid #ccc;
    background-repeat: no-repeat;
    background-position-y: 3px;
    background-position-x: center;
    padding: 5px;
    padding-top: 33px;
    text-align: center;
    margin-left:4px;

    .selected-color{
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid #999;
      border-radius: 4px;
    }
    
     
    
   
    .panel{
      visibility: hidden;
      position: absolute;
      margin-top: 20px;
      margin-left: -50px;
      background-color: #363636;
      border-radius: 5px;
      padding: 3px;
      width: 116px;
      
      z-index: 1;
      .color-box{
       float: left;
        width: 20px;
        height: 20px;
        margin: 1px;
        border: 1px solid #999;
        border-radius: 4px;
      }
    }

  }
  .selected-color:hover .panel{
    visibility: visible;
    
  }
}

