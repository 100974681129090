$spe-base-color:#00A3D9;
$spe-base-color-semi:#00759c;
$spe-base-color-dark:#2f454c;
$spe-base-color-light:#acd1ddce;
$spe-base-color-lighter:#a6d3e288;

$spe-gray-light:#dddddd;
$spe-gray:#2e2e2e;
$spe-gray-dark:#343434;

$layout-breakpoint-small: 960px;